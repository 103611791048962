<template>
  <div>
    <div v-if="item">
      <div class="row d-flex flex-column">
        <div class="mb-3 col-sm-6">
          <label
            for="company_name"
            class="form-label"
          >Company Name</label>
          <input
            id="company_name"
            :value="item.company_name"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-3 col-sm-6">
          <label
            for="address"
            class="form-label"
          >Address</label>
          <input
            id="address"
            :value="item.address"
            type="text"
            class="form-control"
          >
        </div>
      </div>
      <div class="row d-flex flex-column">
        <div class="mb-3 col-sm-6">
          <label
            for="primary_contact"
            class="form-label"
          >Primary Contact</label>
          <input
            id="primary_contact"
            :value="item.primary_contact ? item.primary_contact : null"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-3 col-sm-6">
          <label
            for="phone"
            class="form-label"
          >Phone</label>
          <input
            id="phone"
            :value="item.phone"
            type="text"
            class="form-control"
          >
        </div>
      </div>
      <div class="row d-flex flex-column">
        <div class="mb-3 col-sm-6">
          <label
            for="email"
            class="form-label"
          >Rate Center</label>
          <input
            id="email"
            :value="item.email"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-3 col-sm-6">
          <label
            for="service_provide_status"
            class="form-label"
          >Service Provide Status</label>
          <input
            id="service_provide_status"
            :value="item.service_provide_status ? item.service_provide_status : null"
            type="text"
            class="form-control"
          >
        </div>

        <div class="mb-3 col-sm-6">
          <label
            for="ocn"
            class="form-label"
          >Ocn</label>
          <input
            id="ocn"
            :value="item.ocn"
            type="text"
            class="form-control"
          >
        </div>

        <div class="mb-3 col-sm-6">
          <label
            for="filer"
            class="form-label"
          >499A Filer ID</label>
          <input
            id="filer"
            type="text"
            class="form-control"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "RegistrationDetaile",
  data() {
    return {
      item: {}
    }
  },
  mounted() {
    this.loadDetail()
  },
  methods: {
    loadDetail() {
      if (!this.$route.params.id) return
      axios
          .get(`/admin/registration/${this.$route.params.id}`)
          .then(res => {
            this.item = res.data
          }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>

</style>